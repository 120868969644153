<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="750" max-height="550">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="btn-open" color="#00a5ff" dark v-bind="attrs" v-on="on">
          Open a Ticket
        </v-btn>
      </template>

      <v-card>
        <div
          class="d-flex"
          style="width: 100%; padding-left: 2rem; padding-right: 2rem"
        >
          <div style="width: 97%">
            <v-card-title class="title-ticket lighten-2">
              ¿Necesita registrar un Ticket?
            </v-card-title>
            <v-container id="select_st">
              <v-row>
                <v-col cols="6" class="py-0">
                  <label class="label-ticket">Tipo de Ticket</label>
                  <v-select
                    background-color="#f3f4f7"
                    solo
                    dense
                    primary
                    flat="flat"
                    v-model="kindRequest"
                    item-text="name"
                    item-value="id"
                    :items="lstKind"
                    label="[Select]"
                    @change="changeKind()"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label class="label-ticket">Meta</label>
                  <v-select
                    background-color="#f3f4f7"
                    solo
                    dense
                    primary
                    flat="flat"
                    item-text="name"
                    item-value="id"
                    v-model="reason"
                    :items="showLstReasons"
                    label="[Select]"
                    @change="changeReason()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="6" class="py-0">
                  <label class="label-ticket">Objetivo</label>
                  <v-select
                    background-color="#f3f4f7"
                    solo
                    dense
                    primary
                    flat="flat"
                    v-model="concept"
                    item-text="name"
                    item-value="id"
                    :items="showLstConcepts"
                    label="[Select]"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label class="label-ticket">Descripción</label>
                  <textarea class="txtB_ pa-2" v-model="description"></textarea>
                </v-col>
              </v-row>

              <v-row no-gutters class="rate-ticket mr-0 mt-5 rate-ticket-goals">
                <v-col cols="7">
                  <label class="label-rate">
                    ¿Cómo calificaría nuestro servicio al cliente?
                  </label>
                </v-col>
                <v-col cols="5" class="rate pt-2">
                  <v-rating
                  background-color="black"
                    color="#00A5FF"
                    empty-icon="far fa-star"
                    full-icon="fas fa-star"
                    half-icon="fas fa-star-half-alt"
                    length="5"
                    hover
                    size="16"
                    v-model="rate"
                    :ripple="false"
                  >
                    <template v-slot:item="props" :ripple="false">
                      <v-icon
                        :color="props.isFilled ? 'black' : 'grey lighten-1'"
                        @click="props.click"
                      >
                        {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                      </v-icon>
                    </template></v-rating
                  >
                </v-col>
              </v-row>
              <v-row no-gutters justify="end" class="mt-5">
                <v-btn
                  :disabled="getDis() || loading"
                  @click="sendData()"
                  class="btn-open"
                  color="#00a5ff"
                >
                  Enviar
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="white"
                    :size="25"
                  ></v-progress-circular>
                </v-btn>
              </v-row>
            </v-container>
          </div>
          <div style="width: 3%; padding-top: 0.5rem">
            <div style="width: 100%" class="d-flex justify-center">
              <button @click="dialog = false">
                <img src="https://isc-store.s3.amazonaws.com/advise/btnX.png" />
              </button>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  getConceptsTicket,
  getKindsTicket,
  getReasonsTicket,
  postBrief,
  postTicket,
} from "../../services/services";
export default {
  data() {
    return {
      dialog: false,
      kindRequest: 0,
      reason: 0,
      concept: 0,
      // description: "",
      rate: 1,
      lstKind: [],
      lstReason: [],
      showLstReasons: [],
      lstConcept: [],
      showLstConcepts: [],
      loading: false,
    };
  },
  methods: {
    getData() {
      return {
        kindRequest: this.kindRequest,
        reason: this.reason,
        concept: this.concept,
        description: this.description,
        rate: this.rate,
      };
    },
    getDis() {
      if (!this.kindRequest) return true;
      if (!this.reason) return true;
      if (!this.concept) return true;
      if (!this.description) return true;
      return false;
    },
    changeKind() {
      this.concept = 0;
      this.showLstConcepts = [];
      this.reason = 0;
      this.showLstConcepts = [];
      this.showLstReasons = this.lstReason.filter(
        ({ kin_id }) => kin_id == this.kindRequest
      );
    },
    changeReason() {
      this.concept = 0;
      this.showLstConcepts = this.lstConcept.filter(
        ({ reason_id }) => reason_id == this.reason
      );
    },
    async sendData() {
      this.loading = true;
      const dataTicket = {
        concept_id: this.concept,
        user_id: sessionStorage.getItem("client_id"),
        // user_id: 1,
        satisfaction: this.rate,
        objective_id: this.objective,
        description: this.description,
      };
      const c = this.lstConcept.find(({ id }) => id == this.concept);
      await postTicket(dataTicket);
      this.loading = false;
      this.dialog = false;
      this.concept = 0;
      this.reason = 0;
      this.kindRequest = 0;
      this.description = "";
      this.rate = 1;
      this.$emit("sendData", c.sla);
    },
  },
  props: ["brief", "objective", "description"],
  async created() {
    this.lstKind = await getKindsTicket();
    this.lstReason = await getReasonsTicket();
    this.lstConcept = await getConceptsTicket();
  },
};
</script>
<style scoped>
.btn-open {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  color: white !important;
}
.title-ticket {
  font-family: "Oxygen" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 25px !important;

  color: #2c3852 !important;
  display: flex;
  justify-content: center;
  padding-top: 40px !important;
}
#select_st {
  padding: 40px;
}
#select_st >>> .v-label {
  color: #5d6b88;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
#select_st >>> .v-input {
  color: #5d6b88;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

#select_st >>> .ip_sl {
  width: auto;
  margin-right: 15px;
  flex: inherit;
  height: 35px;
  margin-top: 0;
  padding: 0;
  /* &:last-child {
        margin-right: 0;
    } */
}
.txtB_ {
  font-family: "Oxygen";
  font-style: normal;
  font-size: 12px;
  width: 100%;
  background: #f3f4f6;
  border: 1px solid #c5d0e4;
  border-radius: 3px;
  min-height: 145px;
  max-height: 145px;
}
.txtB_:focus {
  outline: none !important;
  border: 1px solid #00a5ff;
}
.label-ticket {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 164.19%;

  color: #2c3852;
}
.rate-ticket {
  background: #ebf8ff;
  width: 100%;
  height: 60px;
}
.label-rate {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 164.19%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c3a4b;
}
.rate {
  display: flex;
  justify-content: center;
}
</style>
