<template>
	<v-card color="white" flat>
		<v-form>
			<v-container fluid class="container-general-data">
				<v-row no-gutters>
					<v-col>
						<h4 class="sub-title">1. Identificación y Tipo de Empresa</h4>
					</v-col>
				</v-row>
				<v-container fluid>
					<v-row>
						<v-col md cols="6">
							<label>
								Nombre de la Compañia
								<span class="obl_">*</span>
							</label>
							<input class="inpt_" type="text" v-model="brief.nombreCompania" />
						</v-col>
						<v-col cols="6">
							<label>
								Dirección
								<span class="obl_">*</span>
							</label>
							<input class="inpt_" type="text" v-model="brief.direccion" />
						</v-col>
					</v-row>
					<v-row class="mt-0">
						<v-col>
							<v-row no-gutters>
								<v-col cols="12">
									<label>
										Ciudad
										<span class="obl_">*</span>
									</label>
									<input class="inpt_" type="text" v-model="brief.ciudad" />
								</v-col>
							</v-row>
						</v-col>
						<v-col>
							<template>
								<MglMap
									:accessToken="accessToken"
									:mapStyle="mapStyle"
									:center="brief.coordinates"
									:minZoom="0"
									:zoom="5"
									:maxZoom="22"
								>
									<MglMarker
										:coordinates="brief.coordinates"
										color="blue"
										:draggable="true"
										@dragend="setMarker($event)"
									/>
								</MglMap>
							</template>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							<div>
								<label>
									Contacto
									<span class="obl_">*</span>
								</label>
								<input class="inpt_" type="text" v-model="brief.contacto" />
							</div>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							<div>
								<label>
									Correo electrónico del contacto
									<span class="obl_">*</span>
								</label>
								<input class="inpt_" type="text" v-model="brief.email" />
							</div>
						</v-col>
					</v-row>
					<v-row class="mt-0">
						<v-col cols="6" class="pb-0">
							<label>
								Descripción (Industria Actividad)
								<span class="obl_">*</span>
							</label>
							<v-select
								background-color="#f3f4f7"
								label="[Select]"
								solo
								dense
								primary
								flat="flat"
								class="pt-2 select-dg"
								v-model="brief.descripcion"
								:items="listaDescripcion"
								item-text="name"
								item-value="name"
							>
							</v-select>
						</v-col>
						<v-col cols="6" class="pb-0">
							<label>
								Categoría
								<span class="obl_">*</span>
							</label>
							<v-select
								background-color="#f3f4f7"
								label="[Select]"
								solo
								dense
								primary
								flat="flat"
								class="pt-2 select-dg"
								v-model="brief.categoria"
								:items="listaCategoria"
								item-text="name"
								item-value="name"
							></v-select>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							<label>
								Descripción de producto(s) principal(es)
								<span class="obl_">*</span>
							</label>
							<textarea
								class="txtA_ mt-2"
								cols="10"
								rows="5"
								v-model="brief.descripcionProductosPrincipales"
							>
							</textarea>
							<div class="char_">Max 160 caractéres</div>
						</v-col>
					</v-row>
					<v-row class="row-no-padding-top">
						<v-col cols="6" class="col-padding-x">
							<label>
								Tamaño de la Empresa
								<span class="obl_">*</span>
							</label>
							<v-select
								background-color="#f3f4f7"
								label="[Select]"
								class="pt-2 select-dg"
								solo
								dense
								primary
								flat="flat"
								v-model="brief.tamanioEmpresa"
								:items="listaTamanioEmpresa"
								item-text="name"
								item-value="name"
							></v-select>
						</v-col>
						<v-col cols="6" class="col-padding-x">
							<label>
								Cantidad de Empleados
								<span class="obl_">*</span>
							</label>
							<v-select
								background-color="#f3f4f7"
								label="[Select]"
								class="pt-2 select-dg"
								solo
								dense
								primary
								flat="flat"
								v-model="brief.cantidadEmpleados"
								:items="listaCantidadEmpleados"
								item-text="name"
								item-value="name"
							></v-select>
						</v-col>
						<v-col cols="6" class="col-padding-x">
							<label>
								Mercado
								<span class="obl_">*</span>
							</label>
							<v-select
								background-color="#f3f4f7"
								label="[Select]"
								class="pt-2 select-dg"
								solo
								dense
								primary
								flat="flat"
								v-model="brief.mercado"
								:items="listaMercado"
								item-text="name"
								item-value="name"
							></v-select>
						</v-col>
					</v-row>
					<v-row class="row-no-padding-top">
						<v-col cols="12" class="col-padding-x mt-4">
							<label>
								Competencia
								<span class="obl_">*</span>
							</label>
						</v-col>
						<v-col cols="12" class="col-padding-x">
							<label>
								Nombre de la Empresa
								<span class="obl_">*</span>
							</label>
						</v-col>
						<template v-for="(empresa, index) in brief.empresas">
							<v-col cols="10" class="col-padding-x" :key="'empresaDG' + index">
								<input
									:key="'inputKey' + index"
									class="inpt_"
									type="text"
									v-model="empresa.name"
									@input="$emit('changeEmpresa')"
								/>
							</v-col>
							<v-col
								cols="1"
								align-self="center"
								class="col-padding-x"
								:key="'plusEmpresa' + index"
								v-if="index + 1 == brief.empresas.length"
							>
								<v-btn
									icon
									color="#00a5ff"
									@click="
										addEmpresa();
										$emit('changeEmpresa');
									"
								>
									<v-icon dark> mdi-plus-circle </v-icon>
								</v-btn>
							</v-col>
							<v-col
								cols="1"
								align-self="center"
								class="col-padding-x"
								:key="'minusEmpresa' + index"
								v-if="index != 0"
							>
								<v-btn
									icon
									color="#FF7E7E"
									@click="
										removeEmpresa(index);
										$emit('changeEmpresa');
									"
								>
									<v-icon dark> mdi-minus-circle </v-icon>
								</v-btn>
							</v-col>
						</template>
						<v-container class="pl-6">
							<v-row>
								<v-col cols="4" class="col-padding-x" :key="'nameCompetencia' + index">
									<label>
										Red Social
										<span class="obl_">*</span>
									</label>
								</v-col>
								<v-col cols="6" class="col-padding-x" :key="'linkCompetencia' + index">
									<label for="link_web">
										Link de la Web
										<span class="obl_">*</span>
									</label>
								</v-col>
								<template v-for="(competencia, index) in brief.competencias">
									<v-col cols="4" class="col-padding-x" :key="'nameCompetencia' + index">
										<input
											class="inpt_"
											type="text"
											v-model="competencia.redSocial"
											@input="$emit('changeCompetencia')"
										/>
									</v-col>
									<v-col cols="6" class="col-padding-x" :key="'linkCompetencia' + index">
										<input
											class="inpt_"
											type="text"
											v-model="competencia.linkWeb"
											@input="$emit('changeCompetencia')"
										/>
									</v-col>
									<v-col
										cols="1"
										align-self="center"
										class="col-padding-x"
										:key="'plusCompetencia' + index"
										v-if="index + 1 == brief.competencias.length"
									>
										<v-btn
											icon
											color="#00a5ff"
											@click="
												addCompetencia();
												$emit('changeCompetencia');
											"
										>
											<v-icon dark> mdi-plus-circle </v-icon>
										</v-btn>
									</v-col>
									<v-col
										cols="1"
										align-self="center"
										class="col-padding-x"
										:key="'minusCompetencia' + index"
										v-if="index != 0"
									>
										<v-btn
											icon
											color="#FF7E7E"
											@click="
												removeCompetencia(index);
												$emit('changeCompetencia');
											"
										>
											<v-icon dark> mdi-minus-circle </v-icon>
										</v-btn>
									</v-col>
								</template>
							</v-row>
						</v-container>
					</v-row>
				</v-container>
				<v-row no-gutters class="mt-5">
					<v-col>
						<h4 class="sub-title">2. Presencia:</h4>
					</v-col>
				</v-row>
				<v-container fluid>
					<v-row>
						<v-col cols="2" align-self="center" style="display: flex" class="py-0">
							<label>
								País
								<span class="obl_">*</span>
							</label>
						</v-col>
						<v-col cols="10" class="py-0">
							<v-radio-group v-model="brief.pais" row @change="changePais()">
								<v-layout row align-center>
									<v-flex v-for="(pais, index) in listaPaises" :key="'pais' + index">
										<v-radio class="radio-opacity" :label="pais.name" :value="pais.id"></v-radio>
									</v-flex>
								</v-layout>
							</v-radio-group>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="py-0">
							<label for="link_web">
								Local (es)
								<span class="obl_">*</span>
							</label>
						</v-col>
						<v-container class="col-padding-x">
							<v-row v-for="(local, index) in brief.locales" :key="'local' + index" class="mt-0 pl-6">
								<v-col cols="1" class="pa-0" align-self="center">
									<label>
										Local {{ index + 1 }}
										<span class="obl_">*</span>
									</label>
								</v-col>
								<v-col cols="3" class="col-padding-x">
									<v-select
										background-color="#f3f4f7"
										label="[Select Ciudad]"
										class="pt-6 select-dg"
										solo
										dense
										primary
										flat="flat"
										v-model="local.departamento"
										item-text="name"
										item-value="id"
										:items="listaDepartamentos"
										@change="
											$emit('changeLocales');
											changeDepartamento(index);
										"
									>
									</v-select>
								</v-col>
								<v-col cols="3" class="col-padding-x">
									<v-select
										background-color="#f3f4f7"
										label="[Select Provincia]"
										class="pt-6 select-dg"
										solo
										dense
										primary
										flat="flat"
										v-model="local.provincia"
										item-text="name"
										item-value="id"
										:items="local.lstProvincias"
										@change="
											$emit('changeLocales');
											changeProvincia(index);
										"
									>
									</v-select>
								</v-col>
								<v-col cols="3" class="col-padding-x">
									<v-select
										background-color="#f3f4f7"
										label="[Select Distrito]"
										class="pt-6 select-dg"
										solo
										dense
										primary
										flat="flat"
										v-model="local.distrito"
										item-text="name"
										item-value="id"
										:items="local.lstDistritos"
										@change="$emit('changeLocales')"
									>
									</v-select>
								</v-col>
								<v-col
									cols="1"
									align-self="center"
									class="col-padding-x"
									v-if="index + 1 == brief.locales.length"
								>
									<v-btn
										icon
										color="#00a5ff"
										@click="
											addLocal();
											$emit('changeLocales');
										"
									>
										<v-icon dark> mdi-plus-circle </v-icon>
									</v-btn>
								</v-col>
								<v-col cols="1" align-self="center" class="col-padding-x" v-if="index != 0">
									<v-btn
										icon
										color="#FF7E7E"
										@click="
											removeLocal(index);
											$emit('changeLocales');
										"
									>
										<v-icon dark> mdi-minus-circle </v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-row>
				</v-container>
				<v-row no-gutters>
					<v-col>
						<h4 class="sub-title pt-6 pb-2">3. Tipo de Cliente:</h4>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="py-0 px-3">
						<label for="link_web"> Target </label>
					</v-col>
				</v-row>
				<v-container fluid class="py-0">
					<v-row no-gutters>
						<v-col cols="2" align-self="center">
							<label>
								<b>Marketing</b>
								<span class="obl_">*</span>
							</label>
						</v-col>
						<v-col align-self="center">
							<v-radio-group v-model="brief.marketing" row>
								<v-layout row align-center>
									<v-flex v-for="mkting in listaMarketing" :key="mkting.value">
										<v-radio
											class="radio-opacity"
											color="#00A5FF"
											:label="mkting.name"
											:value="mkting.value"
										></v-radio>
									</v-flex>
								</v-layout>
							</v-radio-group>
						</v-col>
					</v-row>
					<!-- B2B -->
					<v-container v-if="brief.marketing == 'b2b' || brief.marketing == 'ambos'" class="pt-0">
						<v-row dense>
							<v-col cols="2" align-self="center">
								<label>
									Perfil B2B
									<span class="obl_">*</span>
								</label>
							</v-col>
							<v-col cols="1" align-self="center">
								<label>Empresa <span class="obl_">*</span></label></v-col
							>
							<v-col cols="6">
								<v-select
									background-color="#f3f4f7"
									label="[Select]"
									class="pt-6 select-dg"
									solo
									dense
									primary
									flat="flat"
									:items="listaEmpresas"
									v-model="brief.perfilB2B.empresa"
								>
								</v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="1" offset="2" class="col-padding-x"><label>Sector</label></v-col>
							<v-col cols="8" class="col-padding-x">
								<v-row>
									<v-col v-for="(sector, idx) in listaSectores" :key="idx" cols="auto" class="py-1">
										<v-checkbox
											class="radio-opacity"
											:label="sector"
											v-model="brief.perfilB2B.sectores"
											:value="sector"
											color="#00A5FF"
											hide-details
										></v-checkbox>
									</v-col>
									<v-col cols="12" class="py-0">
										<v-row>
											<v-col cols="2">
												<v-checkbox
													class="radio-opacity"
													label="Otros"
													v-model="brief.perfilB2B.otros"
													value="otros"
													hide-details
												></v-checkbox>
											</v-col>
											<v-col cols="6">
												<input
													class="inpt_"
													type="text"
													:disabled="!brief.perfilB2B.otros"
													v-model="brief.perfilB2B.descOtros"
												/>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
					<!-- B2C -->
					<v-container
						v-if="brief.marketing == 'b2c' || brief.marketing == 'ambos'"
						class="mt-5"
						style="border-top: 1px solid rgb(216 221 233)"
					>
						<v-row>
							<v-col cols="2" align-self="center">
								<label>
									Perfil B2C
									<span class="obl_">*</span>
								</label>
							</v-col>
							<v-col cols="1" align-self="center">
								<label>Empresa <span class="obl_">*</span></label></v-col
							>
							<v-col cols="6">
								<v-select
									background-color="#f3f4f7"
									label="[Select]"
									class="pt-6 select-dg"
									solo
									dense
									primary
									flat="flat"
									:items="listaEmpresas"
									v-model="brief.perfilB2C.empresa"
								>
								</v-select>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col offset="2" cols="6" md>
								<v-row>
									<v-col md cols="auto">
										<label>
											Género
											<span class="obl_">*</span>
										</label>

										<v-select
											background-color="#f3f4f7"
											label="[Select]"
											class="select-dg"
											solo
											dense
											primary
											flat="flat"
											:items="listaGenero"
											v-model="brief.perfilB2C.genero"
										></v-select>
									</v-col>
									<v-col md cols="auto">
										<label>
											Edad
											<span class="obl_">*</span>
										</label>

										<v-select
											background-color="#f3f4f7"
											label="[Select]"
											class="select-dg"
											solo
											dense
											primary
											flat="flat"
											:items="listaEdades"
											v-model="brief.perfilB2C.edad"
										></v-select>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col offset="2" cols="auto" md>
								<v-row>
									<v-col md cols="auto">
										<label>
											Ubicación
											<span class="obl_">*</span>
										</label>
										<input class="inpt_" type="text" v-model="brief.perfilB2C.ubicacion" />
									</v-col>
									<v-col md cols="auto">
										<label>
											Necesidades
											<span class="obl_">*</span>
										</label>
										<input class="inpt_" type="text" v-model="brief.perfilB2C.necesidades" />
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col offset="2" cols="auto" md>
								<v-row>
									<v-col md cols="auto">
										<label>
											Motivaciones
											<span class="obl_">*</span>
										</label>
										<input class="inpt_" type="text" v-model="brief.perfilB2C.motivaciones" />
									</v-col>
									<v-col md cols="auto">
										<label>
											Hábitos y Hobbies
											<span class="obl_">*</span>
										</label>
										<input class="inpt_" type="text" v-model="brief.perfilB2C.habitos" />
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col offset="2" cols="6" md>
								<v-row>
									<v-col md cols="auto">
										<label>
											NSE
											<span class="obl_">*</span>
										</label>
										<input class="inpt_" type="text" v-model="brief.perfilB2C.nse" />
									</v-col>
									<v-col md cols="auto">
										<label>
											Hábitos en Social Media
											<span class="obl_">*</span>
										</label>
										<input class="inpt_" type="text" v-model="brief.perfilB2C.habitosSocialMedia" />
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
				</v-container>
			</v-container>
		</v-form>
	</v-card>
</template>

<script>
import { MglMap, MglMarker } from "vue-mapbox";
import { getCountries, getDepartaments, getDistricts, getProvinces } from "./../../services/services";
export default {
	name: "DatosGenerales",
	components: {
		MglMap,
		MglMarker,
	},
	data() {
		return {
			accessToken: "pk.eyJ1IjoiYWxmYWxmYTI3IiwiYSI6ImNrZmFidWtqOTA3MXcyeG9nYTlnN2VocjcifQ.Y7rvXSuCyuPwLMkiHenDkQ",
			mapStyle: "mapbox://styles/mapbox/streets-v11",
			listaMarketing: [
				{
					name: "B2B",
					value: "b2b",
				},
				{
					name: "B2C",
					value: "b2c",
				},
				{
					name: "Ambos",
					value: "ambos",
				},
			],
			listaPaises: [],
			listaDepartamentos: [],
			listaTamanioEmpresa: [
				{
					id: 1,
					name: "Facturación anual menor a USD 0.5 Millones",
				},
				{
					id: 2,
					name: "Facturación anual menor a USD 1.0 Millón",
				},
				{
					id: 3,
					name: "Facturación anual menor a USD 5.0 Millones",
				},
				{
					id: 4,
					name: "Facturación anual menor a USD 10.0 Millones",
				},
				{
					id: 4,
					name: "Facturación anual mayor a USD 10.0 Millones",
				},
			],
			listaSectores: [
				"Salud",
				"Educación",
				"Transporte",
				"Hoteles",
				"Arte y Ocio",
				"Informática y Telecomunicaciones",
				"Retail",
				"Gobierno",
			],
			listaEmpresas: ["Pequeña", "Mediana", "Grande"],
			listaGenero: ["Hombre", "Mujer", "Ambos"],
			listaEdades: ["18-30", "31-50", "+50"],
			listaDescripcion: [
				{
					id: 1,
					name: "Agente de bienes raices",
				},
				{
					id: 2,
					name: "Agencia Consultora",
				},
				{
					id: 3,
					name: "Asesor Comercial",
				},
				{
					id: 4,
					name: "Minería",
				},
				{
					id: 5,
					name: "Retail",
				},
				{
					id: 6,
					name: "Textil",
				},
				{
					id: 7,
					name: "Productos Alimenticios",
				},
				{
					id: 8,
					name: "Productos Quimicos",
				},
				{
					id: 9,
					name: "Productos Farmacéuticos",
				},
				{
					id: 10,
					name: "Construcción",
				},
				{
					id: 11,
					name: "Estudio de Abogados",
				},
				{
					id: 12,
					name: "Entidades del Gobierno",
				},
				{
					id: 13,
					name: "Materiales de Construcción",
				},
				{
					id: 13,
					name: "Metalurgia",
				},
				{
					id: 14,
					name: "Siderurgia",
				},
				{
					id: 15,
					name: "Metalmecánica",
				},
				{
					id: 16,
					name: "Cosmética",
				},
				{
					id: 17,
					name: "Salud",
				},
				{
					id: 18,
					name: "Maquinaria",
				},
				{
					id: 19,
					name: "Automóviles",
				},
				{
					id: 20,
					name: "Autopartes",
				},
				{
					id: 21,
					name: "Material Médico",
				},
				{
					id: 22,
					name: "Electricidad",
				},
				{
					id: 23,
					name: "Madera",
				},
				{
					id: 24,
					name: "Otros",
				},
			],
			listaCategoria: [
				{ id: 1, name: "Bienes" },
				{ id: 2, name: "Servicios" },
				{ id: 3, name: "Mixta" },
			],
			listaCantidadEmpleados: [
				{
					id: 1,
					name: "1-10",
				},
				{
					id: 2,
					name: "10-50",
				},
				{
					id: 3,
					name: "50-100",
				},
				{
					id: 4,
					name: "+100",
				},
			],
			listaMercado: [
				{ id: 1, name: "Local" },
				{ id: 2, name: "Nacional" },
				{ id: 3, name: "Multi-Nacional" },
			],
		};
	},
	async created() {
		this.listaPaises = await getCountries();
	},
	watch: {
		async idBrief(val) {
			if (val) {
				this.listaDepartamentos = await getDepartaments(this.brief.pais);
			}
		},
	},
	props: {
		brief: {
			type: Object,
			default: () => {
				return {};
			},
		},
		idBrief: {
			type: Number,
			default: () => {
				return 0;
			},
		},
	},
	computed: {},
	methods: {
		addCompetencia() {
			this.brief.competencias.push({
				redSocial: "",
				linkWeb: "",
			});
		},
		addEmpresa() {
			this.brief.empresas.push({
				name: "",
			});
		},
		removeCompetencia(index) {
			this.brief.competencias.splice(index, 1);
		},
		addLocal() {
			this.brief.locales.push({
				departamento: 0,
				distrito: 0,
				provincia: 0,
				lstProvincias: [],
				lstDistritos: [],
			});
		},
		removeLocal(index) {
			this.brief.locales.splice(index, 1);
		},
		removeEmpresa(index) {
			this.brief.empresas.splice(index, 1);
		},
		async changePais() {
			this.listaDepartamentos = [];
			this.brief.locales.forEach((local) => {
				local.lstProvincias = [];
				local.provincia = 0;
				local.lstDistritos = [];
				local.distrito = 0;
			});
			this.listaDepartamentos = await getDepartaments(this.brief.pais);
			this.brief.lstPaises = this.listaPaises.map((el) => el);
			this.brief.lstDepartamentos = this.listaDepartamentos.map((el) => el);
		},

		async changeDepartamento(index) {
			this.brief.locales[index].lstProvincias = [];
			this.brief.locales[index].provincia = 0;
			this.brief.locales[index].lstDistritos = [];
			this.brief.locales[index].distrito = 0;
			this.brief.locales[index].lstProvincias = await getProvinces(this.brief.locales[index].departamento);
		},

		async changeProvincia(index) {
			this.brief.locales[index].lstDistritos = [];
			this.brief.locales[index].distrito = 0;
			this.brief.locales[index].lstDistritos = await getDistricts(this.brief.locales[index].provincia);
		},
		setMarker(evento) {
			this.brief.coordinates = [evento.marker._lngLat.lng, evento.marker._lngLat.lat];
		},
	},
};
</script>

<style scoped src="./brief.css"></style>
