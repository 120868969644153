<template>
  <v-container v-if="!dataLoaded" style="min-height: 50vh">
    <v-row class="mt-12">
      <v-spacer></v-spacer>
      <v-progress-circular
        :indeterminate="true"
        :size="100"
        :width="5"
        color="light-blue"
      >
      </v-progress-circular>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
  <v-container v-else class="container-brief">
    <v-stepper alt-labels v-model="e1" class="stepper px-16 pb-4">
      <v-stepper-header class="stepper-header">
        <v-stepper-step
          class="steper-complete-tab"
          color="#00a5ff"
          :complete="e1 > 1"
          complete-icon="mdi-numeric-1"
          step="1"
          v-bind:style="getStyle(1)"
          @click="toSteep(1)"
        >
          Datos Generales
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step
          color="#00a5ff"
          :complete="e1 > 2"
          step="2"
          complete-icon="mdi-numeric-2"
          v-bind:style="getStyle(2)"
          @click="toSteep(2)"
        >
          Datos Específicos
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step color="#00a5ff" step="3">
          Análisis FODA
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content step="1">
        <vue-custom-scrollbar class="scroll-area-gh" :settings="settings">
          <datos-generales
            @changeCompetencia="setDisCompetencia()"
            @changeLocales="setDisLstLocales()"
            @changeEmpresa="setDisEmpresas()"
            :brief="brief"
            :idBrief="idBrief"
            v-on:enable-button="enableButton"
          />
        </vue-custom-scrollbar>
      </v-stepper-content>
      <v-stepper-content step="2">
        <vue-custom-scrollbar class="scroll-area-gh" :settings="settings">
          <datos-especificos
            :brief="brief"
            v-on:enable-button="enableButton"
            @changeRed="setDisRedesSociales()"
          />
        </vue-custom-scrollbar>
      </v-stepper-content>
      <v-stepper-content step="3">
        <vue-custom-scrollbar class="scroll-area-gh" :settings="settings">
          <analisis-foda :brief="brief" v-on:enable-button="enableButton" />
        </vue-custom-scrollbar>
      </v-stepper-content>
      <v-card-actions style="padding-left: 2rem; padding-right: 3rem;">
        <div class="obl_">* Campos Obligatorios</div>
        <v-spacer />
        <v-btn
          :disabled="getDis() || loading"
          color="#00a5ff"
          class="btn-sgt"
          @click="nextStep"
        >
          {{ e1 != 3 ? "Siguiente" : idBrief == 0 ? "Guardar" : "Actualizar" }}
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="white"
            :size="25"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-stepper>
  </v-container>
</template>
<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import AnalisisFoda from "./AnalisisFoda.vue";
import DatosEspecificos from "./DatosEspecificos.vue";
import DatosGenerales from "./DatosGenerales.vue";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { getDepartaments, postBrief, putBrief } from "../../services/services";
export default {
  name: "CompanyBrief",
  components: {
    AnalisisFoda,
    DatosEspecificos,
    DatosGenerales,
    vueCustomScrollbar,
  },
  data() {
    return {
      updateMode: false,
      e1: 1,
      enabled: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      dataLoaded: true,
      guardadoEnProceso: false,
      confirmacionDeGuardado: false,
      brief: {
        nombreCompania: "",
        email: "",
        direccion: "",
        ciudad: "",
        contacto: "",
        descripcion: 0,
        categoria: 0,
        descripcionProductosPrincipales: "",
        tamanioEmpresa: 0,
        cantidadEmpleados: 0,
        mercado: 0,
        competencias: [
          {
            nombreEmpresa: "",
            linkWeb: "",
          },
        ],
        pais: 1,
        locales: [
          {
            departamento: 0,
            provincia: 0,
            distrito: 0,
          },
        ],
        marketing: "b2b",
        perfilB2B: {
          empresa: "",
          sectores: [],
          otros: false,
          descOtros: "",
        },
        perfilB2C: {
          intereses: [],
          genero: "",
          edad: [],
        },
        gerencias: [],
        adopcionTransformacionDigital: [],
        numeroEmpleadosCapacitadosRRSS: 1,
        existeArea: "",
        numeroEmpleadosArea: 1,
        redesSociales: [
          {
            nombre: "",
            link: "",
          },
        ],
        website: "",
        campaniasRealizadas: [],
        montoPromedioPublicidad: "",
        foda: {
          fortalezas: [],
          otrasFortalezas: false,
          otrasFortalezasDesc: "",
          debilidades: [],
          otrasDebilidades: false,
          otrasDebilidadesDesc: "",
          aspectoDiferencial: "",
          puntosClave: "",
          objetivosInforme: "",
        },
      },
      disabledCompetencia: false,
      disabledEmpresa: false,
      disabledLocales: false,
      disabledRedes: false,
      loading: false,
    };
  },
  computed: {},
  props: ["brief", "idBrief"],
  methods: {
    async nextStep(val) {
      switch (this.e1) {
        case 1:
          this.e1++;
          this.disableButton();
          break;
        case 2:
          this.e1++;
          this.disableButton();
          break;
        case 3:
          // this.$emit("nextTab");
          // this.saveBrief();
          // this.$emit("nextTab");
          await this.saveBrief();
          break;
        default:
          break;
      }
    },
    disableButton() {
      if (!this.updateMode) {
        this.enabled = false;
      }
    },
    enableButton(isValid) {
      this.enabled = isValid;
    },
    async saveBrief() {
      this.loading = true;
      const client_id = sessionStorage.getItem("client_id");
      // const client_id = 1
      const dataBrief = this.idBrief > 0
        ? { brief: JSON.stringify(this.brief) }
        : {
            user_id:client_id,
            brief: JSON.stringify(this.brief),
          };

      const data = this.idBrief > 0
        ? await putBrief(client_id, dataBrief)
        : await postBrief(dataBrief);
      this.loading = false;
      this.$emit("nextTab", data?.id);
    },
    getStyle(steep) {
      return this.e1 > steep ? { cursor: "pointer" } : {};
    },
    toSteep(steep) {
      if (this.e1 > steep) this.e1 = steep;
    },
    getDis() {
      const objDisabled = {
        e1: this.getDisabledDatosGenerales(),
        e2: this.getDisabledDatosEspecificos(),
        e3: this.getDisabledAnalisisFoda(),
      };
      return this.idBrief ? false : !objDisabled["e" + this.e1];
    },
    getDisabledDatosGenerales() {
      if (!this.brief.nombreCompania) return false;
      if (!this.brief.email) return false;
      if (!this.brief.direccion) return false;
      if (!this.brief.ciudad) return false;
      if (!this.brief.contacto) return false;
      if (!this.brief.descripcion) return false;
      if (!this.brief.categoria) return false;
      if (!this.brief.descripcionProductosPrincipales) return false;
      if (!this.brief.tamanioEmpresa) return false;
      if (!this.brief.cantidadEmpleados) return false;
      if (!this.brief.mercado) return false;
      if (!this.disabledCompetencia) return false;
      if (!this.disabledEmpresa) return false;

      if (!this.disabledLocales) return false;
      if (!this.getDisMarketing()) return false;
      return true;
    },
    getDisabledDatosEspecificos() {
      if (this.brief.gerencias.length == 0) return false;
      if (this.brief.adopcionTransformacionDigital.length == 0) return false;
      if (!this.brief.numeroEmpleadosCapacitadosRRSS) return false;
      if (!this.brief.existeArea) return false;
      if (this.brief.existeArea == "Sí" && !this.brief.numeroEmpleadosArea)
        return false;
      if (!this.disabledRedes) return false;
      if (!this.brief.website) return false;
      if (!this.brief.montoPromedioPublicidad) return false;
      if (this.brief.campaniasRealizadas.length == 0) return false;

      return true;
    },
    getDisabledAnalisisFoda() {
      if (this.brief.fortalezas.length == 0 && !this.brief.otrasFortalezas)
        return false;
      if (this.brief.otrasFortalezas && !this.brief.otrasFortalezasDesc)
        return false;
      if (this.brief.debilidades.length == 0 && !this.brief.otrasDebilidades)
        return false;
      if (this.brief.otrasDebilidades && !this.brief.otrasDebilidadesDesc)
        return false;
      if (!this.brief.aspectoDiferencial) return false;
      if (!this.brief.puntosClave) return false;
      if (!this.brief.objetivosInforme) return false;
      return true;
    },
    setDisCompetencia() {
      let suc = false;
      for (let i = 0; i < this.brief.competencias.length; i++) {
        suc =
          !!this.brief.competencias[i].redSocial &&
          !!this.brief.competencias[i].linkWeb;
        if (!suc) {
          break;
        }
      }
      this.disabledCompetencia = suc;
    },
    setDisEmpresas() {
      let suc = false;
      for (let i = 0; i < this.brief.empresas.length; i++) {
        suc = !!this.brief.empresas[i].name;
        if (!suc) {
          break;
        }
      }
      this.disabledEmpresa = suc;
    },
    setDisLstLocales() {
      let suc = false;
      for (let i = 0; i < this.brief.locales.length; i++) {
        suc =
          this.brief.locales[i].departamento &&
          this.brief.locales[i].provincia &&
          this.brief.locales[i].distrito;
        if (!suc) {
          break;
        }
      }
      this.disabledLocales = suc;
    },
    setDisRedesSociales() {
      let suc = false;
      for (let i = 0; i < this.brief.redesSociales.length; i++) {
        suc =
          this.brief.redesSociales[i].nombre &&
          this.brief.redesSociales[i].link;
        if (!suc) {
          break;
        }
      }
      this.disabledRedes = suc;
    },

    getDisMarketing() {
      const validationMarketing = {
        b2b: this.getDisMarketingB2B(),
        b2c: this.getDisMarketingB2C(),
        ambos: this.getDisMarketingB2B() && this.getDisMarketingB2C(),
      };
      return validationMarketing[this.brief.marketing];
    },
    getDisMarketingB2B() {
      if (!this.brief.perfilB2B.empresa) return false;
      if (this.brief.perfilB2B.otros && !this.brief.perfilB2B.descOtros)
        return false;
      return true;
    },
    getDisMarketingB2C() {
      if (!this.brief.perfilB2C.empresa) return false;
      if (!this.brief.perfilB2C.genero) return false;
      if (!this.brief.perfilB2C.edad) return false;
      if (!this.brief.perfilB2C.ubicacion) return false;
      if (!this.brief.perfilB2C.necesidades) return false;
      if (!this.brief.perfilB2C.motivaciones) return false;
      if (!this.brief.perfilB2C.habitos) return false;
      if (!this.brief.perfilB2C.nse) return false;
      if (!this.brief.perfilB2C.habitosSocialMedia) return false;
      return true;
    },
  },
  created() {},
  mounted() {},
  watch: {
    async idBrief(val) {
      if (val) {
        await getDepartaments(this.brief.pais);
      }
    },
  },
};
</script>
<style>
body ::-webkit-scrollbar {
  width: 5px;
  background: white;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #00a5ff;
}

body ::-webkit-scrollbar-track {
  background-color: white;
}

.char_ {
  font-size: 0.75em;
  text-align: right;
  color: #5d6b88;
}
.obl_ {
  color: #ff7e7e;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
}

.v-stepper__step:not(.v-stepper__step--inactive) .v-stepper__label {
  color: #00a5ff !important;
}
.v-stepper__label {
  /* font-weight: 500; */
  text-align: center;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
.v-stepper__step.v-stepper__step--complete + hr {
  border-color: #00a5ff !important;
  background: #00a5ff !important;
}

.stepper-container .theme--light.v-stepper .v-stepper__label {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  line-height: 164.19%;
  color: #2c3852;
}

.stepper-container
  .theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: #d8d9d9;
}

/* .v-stepper__content .container--fluid {
  padding: 0 4% 0 3.5%;
}

.v-stepper__content .container--fluid .container--fluid {
  padding: 0;
  padding-left: 1rem;
}

@media (max-width: 1435px){
  .v-stepper__content .container--fluid {
    padding: 0 4% 0 3%;
  }
}

@media (max-width: 1420px){
  .v-stepper__content .container--fluid {
    padding: 0 3% 0 2.5%;
  }
}

@media (max-width: 1400px){
  .v-stepper__content .container--fluid {
    padding: 0 2.5% 0 2%;
  }
} */
</style>
<style scoped>
::v-deep.stepper {
  margin: auto;
  align-content: center;
  max-width: 1100px !important;
  border: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

::v-deep.stepper-header {
  margin: auto;
  max-width: 1050px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.divider {
  margin: 35px -76px 0 !important;
  border-width: 3px;
}

.divider-success {
  background: #00a5ff;
  border-width: 3px;
}

.scroll-area-gh {
  position: relative;
  margin: auto;
  height: 400px;
}
::v-deep.scroll-area-gh > .ps__rail-y:hover > .ps__thumb-y {
  background-color: #00a5ff;
}

.btn-sgt {
  color: white !important;
  text-transform: none !important;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.stepper-container .v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
