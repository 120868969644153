<template>
  <v-card color="white" flat>
    <v-form class="form-specific-dates">
      <v-container fluid>
        <v-row no-gutters>
          <v-col>
            <h4 class="sub-title">4. Organización</h4>
          </v-col>
        </v-row>
        <v-container fluid>
          <v-row>
            <v-col cols="2">
              <label>
                Gerencias
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col cols="10">
              <v-row
                ><v-col
                  cols="auto"
                  v-for="(gerencia, idx) in listaGerencias"
                  :key="idx"
                  class="py-1"
                >
                  <v-checkbox
                    class="radio-opacity"
                    :label="gerencia.name"
                    v-model="brief.gerencias"
                    :value="gerencia.name"
                    color="#00A5FF"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col cols="2">
              <label>
                Adopción de la transformación digital
                <span class="obl_">*</span>
              </label> </v-col
            ><v-col
              cols="auto"
              v-for="(transformacion, idx) in listaTransformacion"
              :key="idx"
              class="py-1"
            >
              <v-checkbox
                class="radio-opacity"
                :label="transformacion.name"
                v-model="brief.adopcionTransformacionDigital"
                :value="transformacion.name"
                color="#00A5FF"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="2" class="col-padding-x">
              <label>
                Número de empleados capacitados en RRSS
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col align-self="center" class="col-padding-x">
              <v-radio-group v-model="brief.numeroEmpleadosCapacitadosRRSS" row>
                <v-layout row align-center>
                  <v-flex
                    v-for="(numero, index) in listaNumeros"
                    :key="'esp-num-' + index"
                  >
                    <v-radio
                      class="radio-opacity"
                      :label="numero"
                      :value="numero"
                      color="#00A5FF"
                    ></v-radio>
                  </v-flex>
                </v-layout>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label>
                Marketing Digital
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col cols="12" class="py-0">
              <label>
                ¿Existe área?
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col cols="6" class="col-padding-x">
              <v-select
                class="select-dg"
                background-color="#f3f4f7"
                label="[Select]"
                solo
                dense
                primary
                flat="flat"
                :items="listaArea"
                v-model="brief.existeArea"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="py-0">
              <label>
                Si existe: ¿Cuánta gente la compone?
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col align-self="center">
              <v-radio-group v-model="brief.numeroEmpleadosArea" row>
                <v-layout row align-center>
                  <v-flex
                    v-for="(numero, index) in listaNumeros"
                    :key="'esp-numgente-' + index"
                  >
                    <v-radio
                      class="radio-opacity"
                      :label="numero"
                      :value="numero"
                      color="#00A5FF"
                    ></v-radio>
                  </v-flex>
                </v-layout>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <v-row no-gutters class="mt-5">
          <v-col>
            <h4 class="sub-title">5. Presencia en Redes Sociales</h4>
          </v-col>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="3" class="px-3 py-0">
              <label for="company_name">
                Red Social
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col cols="7" class="px-3 py-0">
              <label for="email">
                Link
                <span class="obl_">*</span>
              </label>
            </v-col>
            <template v-for="(redes, index) in brief.redesSociales">
              <v-col cols="3" class="col-padding-x">
                <input
                  v-model="redes.nombre"
                  class="inpt_"
                  type="text"
                  @input="$emit('changeRed')"
                />
              </v-col>
              <v-col cols="7" class="col-padding-x">
                <input
                  v-model="redes.link"
                  class="inpt_"
                  type="text"
                  @input="$emit('changeRed')"
                />
              </v-col>
              <v-col
                cols="1"
                align-self="center"
                class="px-3 py-0"
                v-if="index + 1 == brief.redesSociales.length"
              >
                <v-btn
                  icon
                  color="#00a5ff"
                  @click="
                    addRed();
                    $emit('changeRed');
                  "
                >
                  <v-icon dark> mdi-plus-circle </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="1"
                align-self="center"
                class="px-3 py-0"
                v-if="index != 0"
              >
                <v-btn
                  icon
                  color="#FF7E7E"
                  @click="
                    removeRed(index);
                    $emit('changeRed');
                  "
                >
                  <v-icon dark> mdi-minus-circle </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-container>
        <v-row no-gutters class="pt-5">
          <v-col>
            <h4 class="sub-title">
              6. Marketing Digital<span class="obl_"> *</span>
            </h4>
          </v-col>
        </v-row>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <div>
                <label for="contact">
                  Website
                  <span class="obl_">*</span>
                </label>
                <br />
                <input v-model="brief.website" class="inpt_" type="text" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <label>
                Campañas de publicidad realizadas en:
                <span class="obl_">*</span>
              </label>
            </v-col>
            <v-col
              cols="auto"
              v-for="(campania, idx) in listaCampanias"
              :key="idx"
              class="py-1"
            >
              <v-checkbox
                class="radio-opacity"
                :label="campania.name"
                v-model="brief.campaniasRealizadas"
                :value="campania.name"
                color="#00A5FF"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <div>
                <label for="contact">
                  Monto Promedio de publicidad mensual en Redes (USD)
                  <span class="obl_">*</span>
                </label>
                <br />
                <input
                  v-model="brief.montoPromedioPublicidad"
                  class="inpt_"
                  type="number"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  name: "DatosEspecificos",
  data() {
    return {
      listaGerencias: [
        { name: "Gerencia de Operaciones", selected: false },
        { name: "Gerencia de RRHH", selected: false },
        { name: "Gerencia General", selected: false },
        { name: "Gerencia Comercial", selected: false },
        { name: "Gerencia de Marketing", selected: false },
        { name: "Community Manager", selected: false },
      ],
      listaTransformacion: [
        { name: "Flujos de Procesos de Negocio", selected: false },
        { name: "Sistema de Business Intelligence", selected: false },
        { name: "ERP", selected: false },
        { name: "CRM", selected: false },
      ],
      listaNumeros: ["0", "1-5", "5+"],
      listaArea: ["Sí", "No"],
      listaCampanias: [
        {
          name: "Redes Sociales",
          selected: false,
        },
        {
          name: "Google Ads",
          selected: false,
        },
        {
          name: "Prensa Escrita",
          selected: false,
        },
        {
          name: "Radio",
          selected: false,
        },
        {
          name: "TV",
          selected: false,
        },
      ],
      selectedNumEmpleados: 0,
      selectedGente: 0,
      selectedArea: 0,
      selectCampaniasPublicidad: [],
    };
  },
  computed: {
    // ...mapGetters(["brief"]),
  },
  props: ["brief"],
  created() {
    this.selectedNumEmpleados = this.brief.numeroEmpleadosCapacitadosRRSS;
    this.selectedGente = this.brief.numeroEmpleadosArea;
    this.selectedArea = this.brief.existeArea;
  },
  methods: {
    addRed() {
      this.brief.redesSociales.push({
        nombre: "",
        link: "",
      });
    },
    removeRed(index) {
      this.brief.redesSociales.splice(index, 1);
    },
  },
};
</script>

<style scoped src="./brief.css"></style>
